import axios from 'axios';

export class SessionChecker {
    constructor(router) {
        this.router = router;
        this.lastCheckTime = 0;
        this.checkInterval = 60000;
        this.isChecking = false;
        this.lastUserData = null;

        // Define public routes that should never trigger session checks
        this.publicPaths = [
            /^\/[^/]+\/activate\/[^/]+$/,  // Activation routes like /de/activate/{token}
            /^\/[^/]+\/login$/,            // Login routes
            /^\/[^/]+\/reset-password/,    // Password reset routes
            /^\/[^/]+\/confirm-email/      // Email confirmation routes
        ];

        this.adminPaths = ['/admin'];
        this.customerPaths = ['/dashboard', '/settings', '/messenger'];
    }

    isAdminRoute(path) {
        return this.adminPaths.some(route => path.includes(route));
    }

    isCustomerRoute(path) {
        const currentLang = localStorage.getItem('preferredLanguage') || 'en';
        return this.customerPaths.some(route =>
            path.includes(`/${currentLang}${route}`)
        );
    }

    isPublicRoute(path) {
        return this.publicPaths.some(pattern => pattern.test(path));
    }

    isProtectedRoute(path) {
        // First check if it's explicitly public
        if (this.isPublicRoute(path)) {
            return false;
        }

        const currentLang = localStorage.getItem('preferredLanguage') || 'en';
        const protectedRoutes = ['/dashboard', '/settings', '/messenger'];
        return protectedRoutes.some(route =>
            path.includes(`/${currentLang}${route}`)
        );
    }

    handleSessionExpired() {
        // Don't redirect if we're on a public route
        if (this.isPublicRoute(window.location.pathname)) {
            return;
        }

        // Clear user data
        this.lastUserData = null;
        localStorage.removeItem('user');

        // Get language and construct login URL
        const preferredLang = localStorage.getItem('preferredLanguage') || 'en';
        const loginUrl = `/${preferredLang}/login?session_expired=true`;

        // Only redirect if we're not already heading to login
        if (!window.location.pathname.includes('/login')) {
            // Use replace instead of href to prevent adding to history
            window.location.replace(loginUrl);
        }
    }

    async checkRouteAccess(path) {
        const session = await this.checkSession(true);
        if (!session) return false;

        if (this.isAdminRoute(path)) {
            return this.lastUserData?.roles?.includes('ROLE_ADMIN');
        }

        if (this.isCustomerRoute(path)) {
            return !this.lastUserData?.roles?.includes('ROLE_ADMIN');
        }

        return true;
    }

    // Rest of the SessionChecker class remains the same
    async checkSession(force = false) {
        if (this.isChecking) {
            return new Promise(resolve => {
                const checkInterval = setInterval(() => {
                    if (!this.isChecking) {
                        clearInterval(checkInterval);
                        resolve(this.performCheck());
                    }
                }, 100);
            });
        }

        if (!force && !this.shouldCheck()) {
            return !!this.lastUserData;
        }

        return this.performCheck();
    }

    async performCheck() {
        try {
            this.isChecking = true;
            const response = await axios.get('/api/check-session');
            this.lastCheckTime = Date.now();

            if (response.data.isAuthenticated) {
                this.lastUserData = response.data.user;
                if (this.lastUserData.language) {
                    const currentLang = localStorage.getItem('preferredLanguage');
                    if (currentLang !== this.lastUserData.language) {
                        localStorage.setItem('preferredLanguage', this.lastUserData.language);
                    }
                }
                return true;
            } else {
                this.lastUserData = null;
                this.handleSessionExpired();
                return false;
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                this.lastUserData = null;
                this.handleSessionExpired();
                return false;
            }
            return !!this.lastUserData;
        } finally {
            this.isChecking = false;
        }
    }

    shouldCheck() {
        return Date.now() - this.lastCheckTime >= this.checkInterval;
    }

    getCurrentUserData() {
        return this.lastUserData;
    }

    setupRouterGuard() {
        this.router.beforeEach(async (to, from, next) => {
            // Always allow access to public routes
            if (this.isPublicRoute(to.path)) {
                return next();
            }

            try {
                const hasAccess = await this.checkRouteAccess(to.path);
                if (!hasAccess) {
                    const lang = localStorage.getItem('preferredLanguage') || 'en';

                    // If user is not authenticated at all, redirect to login
                    if (!this.lastUserData) {
                        return next(`/${lang}/login?session_expired=true`);
                    }

                    // If user is authenticated but doesn't have access, redirect based on role
                    if (this.lastUserData?.roles?.includes('ROLE_ADMIN')) {
                        return next('/admin/dashboard');
                    } else {
                        return next(`/${lang}/dashboard`);
                    }
                }

                next();
            } catch (error) {
                // If there's an error checking access, redirect to login
                const lang = localStorage.getItem('preferredLanguage') || 'en';
                return next(`/${lang}/login?session_expired=true`);
            }
        });
    }
}